const PddKeyBenefits = ({smallStyle, language}) =>{
	if (language == "EN")
	{
		return(
			<div>
				<div className="KeyBenefitsHeader">
					<div className={!smallStyle ? "KeyBenefitsHeaderH1" : "KeyBenefitsHeaderH1Small"}>
						Key Benefits
					</div>
					<div className={!smallStyle ? "KeyBenefitsHeaderH3" : "KeyBenefitsHeaderH3Small"}>
						Power Your Business by Partnering with Us!
					</div>
				</div>

				{/*Benefits List */}

				<div className="BenefitsListBlockTop">
					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								1
							</div>
							<div className="BenefitsListTitle">
								One-Stop Solution
							</div>
							<div className="BenefitsListContent">
								For all your distribution needs: warehousing, sales, delivery, payments, collection & reporting.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								2
							</div>
							<div className="BenefitsListTitle">
								Local Understanding
							</div>
							<div className="BenefitsListContent">
								We possess a deeper understanding of the needs and preferences of the Indonesian consumers, navigating local regulations and requirements more effectively.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								3
							</div>
							<div className="BenefitsListTitle">
								Flexible Solutions
							</div>
							<div className="BenefitsListContent">
								We offer customized solutions to meet your unique distribution needs, from warehousing to transportation.
							</div>
						</div>
					</div>

					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								4
							</div>
							<div className="BenefitsListTitle">
								Market Expansion
							</div>
							<div className="BenefitsListContent">
								Enhance the brand's presence in Indonesia's rapidly growing market. Leverage the local distribution infrastructure to reach consumers nationwide.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								5
							</div>
							<div className="BenefitsListTitle">
								Experienced Team
							</div>
							<div className="BenefitsListContent">
								Our team of experts has decades worth of experience in the industry and is dedicated to ensuring your satisfaction.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								6
							</div>
							<div className="BenefitsListTitle">
								Tech-Enabled Stock Availability
							</div>
							<div className="BenefitsListContent">
								Our tech-enabled system ensure highest-level stock management practices to prevent out of stocks and excess inventory.
							</div>
						</div>

						
					</div>
				</div>
			</div>
		)
	}
	else if (language == "ID")
	{
		return(
			<div>
				<div className="KeyBenefitsHeader">
					<div className={!smallStyle ? "KeyBenefitsHeaderH1" : "KeyBenefitsHeaderH1Small"}>
						Manfaat Utama
					</div>
					<div className={!smallStyle ? "KeyBenefitsHeaderH3" : "KeyBenefitsHeaderH3Small"}>
						Maksimalkan Bisnis Anda dengan Bekerja Sama dengan Kami!
					</div>
				</div>

				{/*Benefits List */}

				<div className="BenefitsListBlockTop">
					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								1
							</div>
							<div className="BenefitsListTitle">
								Solusi Satu Pintu
							</div>
							<div className="BenefitsListContent">
								Untuk semua kebutuhan distribusi Anda: pergudangan, penjualan, pengiriman, pembayaran, penagihan, & pelaporan.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								2
							</div>
							<div className="BenefitsListTitle">
								Pemahaman Lokal
							</div>
							<div className="BenefitsListContent">
								Kami memiliki pemahaman yang lebih mendalam tentang kebutuhan dan preferensi konsumen Indonesia, serta dapat menavigasi peraturan dan persyaratan lokal dengan lebih efektif.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								3
							</div>
							<div className="BenefitsListTitle">
								Solusi Fleksibel
							</div>
							<div className="BenefitsListContent">
								Kami menawarkan solusi yang disesuaikan untuk memenuhi kebutuhan distribusi unik Anda, mulai dari pergudangan hingga transportasi.
							</div>
						</div>
					</div>

					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								4
							</div>
							<div className="BenefitsListTitle">
								Ekspansi Pasar
							</div>
							<div className="BenefitsListContent">
								Tingkatkan kehadiran merek di pasar Indonesia yang berkembang pesat. Manfaatkan infrastruktur distribusi lokal untuk menjangkau konsumen di seluruh negeri.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								5
							</div>
							<div className="BenefitsListTitle">
								Tim Berpengalaman
							</div>
							<div className="BenefitsListContent">
								Tim ahli kami memiliki pengalaman puluhan tahun di industri ini dan berdedikasi untuk memastikan kepuasan Anda.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								6
							</div>
							<div className="BenefitsListTitle">
								Ketersediaan Stok Teknologi
							</div>
							<div className="BenefitsListContent">
								Sistem berbasis teknologi kami memastikan praktik manajemen stok tingkat tertinggi untuk mencegah kekurangan stok dan kelebihan inventaris.
							</div>
						</div>

						
					</div>
				</div>
			</div>
		)
	}
}

export default PddKeyBenefits;