import './App.css';
import React from "react";
import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useLocation} from "react-router-dom";
import "./App.css";


//Components
import NavBar from "./components/navBar/navBar";
import Footer from "./components/Footer/footer";

//Pages
import Home from "./pages/home/home";

const App = ()=> {
  const initialized = useRef(false);
  const [language, setLanguage] = useState("ID");

  const onSetLanguage = ((lang)=>{
    localStorage.setItem("language", lang);

    setLanguage(lang);
  })

  const getLanguage = () =>{
    let lang = localStorage.getItem("language");

    if (!lang)
    {
      lang = "ID";
    }
    onSetLanguage(lang);
  }

  useEffect(()=>{
    if (!initialized.current)
    {
      getLanguage();
      initialized.current = true;
    }
    
  }, []);

  return (
    <div className="App">
      <NavBar onSetLanguage = {onSetLanguage} language = {language}/>
       <Routes>
         <Route path="/" element= { <Home language={language}/> }/>
       </Routes>
       <Footer/>
    </div>
  );
}

export default App;
