import marketFootprintHoreca from "../../images/market_footprint_horeca.png";
import marketFootprintModernTrade from "../../images/market_footprint_modernTrade.png";
import marketFootprintOnline from "../../images/market_footprint_onlineDigital.png";
import marketFootprintPointOfInterest from "../../images/market_footprint_pointOfInterest.png";
import marketFootprintSpecialtyStore from "../../images/market_footprint_specialtyStore.png";
import marketFootprintKoperasi from "../../images/market_footprint_koperasi.png";
import marketFootprintGeneralTrade from "../../images/market_footprint_generalTrade.png";
import marketFootprintGeneralTradeID from "../../images/market_footprint_generalTrade_ID.png";

const PddOurMarketFootprint = ({smallStyle, language}) =>{
	if (language == "EN")
	{
		return(
			<div>
				<h1>Our Market Footprints</h1>
				<h2>Expand your market presences beyond traditional GT & MT channels</h2>
				<div className={!smallStyle ? "MarketFootPrintRowOne" : "MarketFootPrintRowOneSmall"}>
					<img src={marketFootprintHoreca}/>
					<img src={marketFootprintModernTrade}/>
					<img src={marketFootprintOnline}/>
					<img src={marketFootprintPointOfInterest}/>
				</div>
				<div className={!smallStyle ? "MarketFootPrintRowTwo" : "MarketFootPrintRowTwoSmall"}>
					<img src={marketFootprintSpecialtyStore}/>
					<img src={marketFootprintKoperasi}/>
					<img src={marketFootprintGeneralTrade}/>
				</div>
			</div>
		)
	}
	else if (language == "ID")
	{
		return(
			<div>
				<h1>Jejak Pasar Kami</h1>
				<h2>Perluas kehadiran pasar Anda di luar saluran GT & MT tradisional</h2>
				<div className={!smallStyle ? "MarketFootPrintRowOne" : "MarketFootPrintRowOneSmall"}>
					<img src={marketFootprintHoreca}/>
					<img src={marketFootprintModernTrade}/>
					<img src={marketFootprintOnline}/>
					<img src={marketFootprintPointOfInterest}/>
				</div>
				<div className={!smallStyle ? "MarketFootPrintRowTwo" : "MarketFootPrintRowTwoSmall"}>
					<img src={marketFootprintSpecialtyStore}/>
					<img src={marketFootprintKoperasi}/>
					<img src={marketFootprintGeneralTradeID}/>
				</div>
			</div>
		)
	}
}

export default PddOurMarketFootprint;