import React from 'react'
import './footer.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router";

//images
import logo from "../../images/pasarnow_logo.png";
import appStoreBtn from "../../images/AppStoreDownloadBtn.png";
import playStoreBtn from "../../images/PlayStoreDownloadBtn.png";
import instagramBtn from "../../images/instagram_white.png";
import linkedinBtn from "../../images/linkedin_white.png";


const Footer = () =>{

	const navigate = useNavigate();

	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 920)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 920)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])


	const GoToPage = (url) =>{
    	navigate(url);
  	}

	return(
		<div className="footerArea">
			<br/>
			{/*<div className={!smallStyle ? "ContactArea" : "ContactAreaSmall"}>
				<div className={!smallStyle ? "contactInfo" : "contactInfoSmall"}><label className={!smallStyle ? "SendEmail" : "SendEmailSmall"}>E-MAIL:</label> <a href="mailto:cs@pasarnow.com"> cs@pasarnow.com</a></div>
			
				<div className={!smallStyle ? "contactInfo" : "contactInfoSmall"}><label className={!smallStyle ? "SendEmail" : "SendEmailSmall"}> WHATSAPP:</label> <a href="https://wa.me/+6281388206151" target="_blank">+62 813-8820-6151</a></div>
			</div>*/}

			{/*<div className={!smallStyle ? "DownloadAppSection" : "DownloadAppSectionSmall"}>
				<label>Download the Pasarnow App for iOS and Android</label>
				<div className="DownloadAppBtns">
					<a href="https://apps.apple.com/id/app/pasarnow/id1517777453"><img src={appStoreBtn} style={{marginRight : "10px"}}/></a>
					<a href="https://play.google.com/store/apps/details?id=com.jamanow.pasarnow&hl=id"><img src={playStoreBtn}/></a>
				</div>
			</div>*/}
			{/*<hr style={{width: "90%"}}/>*/}
			<div className={!smallStyle ? "FinalInfo" : "FinalInfoSmall"}>
				<label>STAY CONNECTED</label>
				<a href="https://www.instagram.com/pasarnow/?hl=en" target="_blank"><img src={instagramBtn}/></a>
				<a href="https://www.linkedin.com/company/pasarnowid" target="_blank"><img src={linkedinBtn}/></a>

				<label className={!smallStyle ? "CopyrightText" : "CopyrightTextSmall"}>Copyright | PT Usaha Pintar Pratama. All Rights Reserved</label>
			</div>
		</div>
	)
}


export default Footer;