import warehouseIcon from "../../images/central_warehouse_icon.png";
import coverageIcon from "../../images/coverage_area_icon.png";
import fleetIcon from "../../images/internal_fleet_icon.png";
import salesIcon from "../../images/sales_person_icon.png";
import pasarnowVan from "../../images/pasarnow_van.png";

const PddDistributionCapabilities = ({smallStyle, language}) =>{
	if (language == "EN")
	{
		return(
			<div>
				<h1>Our Distribution Capabilities</h1>
				<h2>Expand Your Reach by Tapping into Our Expanding Network</h2>
				<div className={!smallStyle ? "distributionCapabilitiesBlockTop" : "distributionCapabilitiesBlockTopSmall"}>
					<div className={!smallStyle ? "distributionCapabilitiesBlockRow" : "distributionCapabilitiesBlockRowSmall"}>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={warehouseIcon}/>
							<h2>Central Warehouse</h2>
							<label>Depok, West Java</label>
						</div>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={coverageIcon}/>
							<h2>Coverage Area</h2>
							<label>20+ municipalities</label>
						</div>
					</div>
					<div className={!smallStyle ? "distributionCapabilitiesBlockRow" : "distributionCapabilitiesBlockRowSmall"}>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={fleetIcon}/>
							<h2>Internal Fleets</h2>
							<label>25+ Units</label>
						</div>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={salesIcon}/>
							<h2>Sales Personnels</h2>
							<label>40+ personnels</label>
						</div>
					</div>
				</div>

				<div className="distributionCapabilitiesBottomDivider">
					<img src={pasarnowVan}/>
					<div className="FloorBackground">
					</div>
				</div>
			</div>
		)
	}
	else if (language == "ID")
	{
		return(
			<div>
				<h1>Kemampuan Distribusi Kami</h1>
				<h2>Perluas Jangkauan Anda dengan Memanfaatkan Jaringan Kami yang Terus Berkembang</h2>
				<div className={!smallStyle ? "distributionCapabilitiesBlockTop" : "distributionCapabilitiesBlockTopSmall"}>
					<div className={!smallStyle ? "distributionCapabilitiesBlockRow" : "distributionCapabilitiesBlockRowSmall"}>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={warehouseIcon}/>
							<h2>Gudang Pusat</h2>
							<label>Depok, Jawa Barat</label>
						</div>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={coverageIcon}/>
							<h2>Area Cakupan</h2>
							<label>20+ kota/kabupaten</label>
						</div>
					</div>
					<div className={!smallStyle ? "distributionCapabilitiesBlockRow" : "distributionCapabilitiesBlockRowSmall"}>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={fleetIcon}/>
							<h2>Armada Internal</h2>
							<label>25+ Unit</label>
						</div>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={salesIcon}/>
							<h2>Personel Penjualan</h2>
							<label>40+ personel</label>
						</div>
					</div>
				</div>

				<div className="distributionCapabilitiesBottomDivider">
					<img src={pasarnowVan}/>
					<div className="FloorBackground">
					</div>
				</div>
			</div>
		)
	}
}

export default PddDistributionCapabilities;