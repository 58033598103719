import productImage from "../../images/products.png";

const PddOurProducts = ({language}) =>{
	if (language == "EN")
	{
		return(
			<div>
				<h1>Our Products</h1>
				<h2>We have proudly serve many renowned & emerging brands in Indonesia since 2021. Through our seamless efficiency, we have successfully distributed over 10 million products through our extensive network.</h2>
				<img src={productImage}/>
				<h2>Our specialty includes Food & Beverages, Health & Personal Care, Baby & Children's Products, and Pet Products.</h2>
			</div>
		)
	}
	else if (language == "ID")
	{
		return(
			<div>
				<h1>Produk Kami</h1>
				<h2>Kami dengan bangga telah melayani banyak merek terkenal & berkembang di Indonesia sejak 2021. Melalui efisiensi yang lancar, kami telah berhasil mendistribusikan lebih dari 10 juta produk melalui jaringan kami yang luas.</h2>
				<img src={productImage}/>
				<h2>Keahlian kami mencakup Makanan & Minuman, Kesehatan & Perawatan Pribadi, Produk Bayi & Anak, serta Produk Hewan Peliharaan.</h2>
			</div>
		)
	}
}

export default PddOurProducts;