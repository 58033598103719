const PddIntro= ({language}) =>{

	if (language == "EN")
	{
		return(
			<div>
				<h1>Pasarnow Distribusi Digital</h1>
				<h2>The Trusted Distribution Partner to Grow Your Business</h2>
				<p>Pasarnow Distribusi Digital (“PDD”) is a digitally-enabled distribution company that collaborates with leading manufacturers in Indonesia to distribute quality products to various types of customers.</p>
				<p>Our vision is to become the national champion distribution company in Indonesia, providing nationwide coverage & service excellence, and to be trusted & loved by our Brand Partners.</p>
			</div>
		)
	}
	else if (language == "ID")
	{
		return(
			<div>
				<h1>Pasarnow Distribusi Digital</h1>
				<h2>The Trusted Distribution Partner to Grow Your Business</h2>
				<p>Pasarnow Distribusi Digital ("PDD") adalah perusahaan distribusi yang berbasis digital dan bekerja sama dengan produsen terkemuka di Indonesia untuk mendistribusikan produk-produk berkualitas kepada berbagai jenis pelanggan.</p>
				<p>Visi kami adalah menjadi perusahaan distribusi nasional terkemuka di Indonesia, dengan cakupan dan layanan yang luas di seluruh negeri, serta dipercaya dan dicintai oleh Mitra Merek kami.</p>
			</div>
		)
	}
	
}

export default PddIntro;