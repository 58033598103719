import valueProposition1 from "../../images/Value_Proposition_1.png";
import valueProposition2 from "../../images/Value_Proposition_2.png";
import valueProposition3 from "../../images/Value_Proposition_3.png";
import valueProposition1ID from "../../images/Value_Proposition_1_ID.png";
import valueProposition2ID from "../../images/Value_Proposition_2_ID.png";
import valueProposition3ID from "../../images/Value_Proposition_3_ID.png";


const PddValues = ({smallStyle, language}) =>{
	if (language == "EN")
	{
		return(
			<div>
				<h1>PDD Value Propositions</h1>
				<h2>We help Brands & Principals penetrate alternative & mass-market channels more effectively and provide 3 key main value propositions:</h2>
				<div className={!smallStyle ? "valuePropositionImages" : "valuePropositionImagesSmall"}>
					<img src={valueProposition1}/>
					<img src={valueProposition2}/>
					<img src={valueProposition3}/>
				</div>
			</div>
		)
	}
	else if (language == "ID")
	{
		return(
			<div>
				<h1>Proposisi Nilai PDD</h1>
				<h2>Kami membantu Merek & Prinsipal untuk menembus saluran alternatif & pasar massal dengan lebih efektif dan memberikan 3 proposisi nilai utama:</h2>
				<div className={!smallStyle ? "valuePropositionImages" : "valuePropositionImagesSmall"}>
					<img src={valueProposition1ID}/>
					<img src={valueProposition2ID}/>
					<img src={valueProposition3ID}/>
				</div>
			</div>
		)
	}
}

export default PddValues;