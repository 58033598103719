const PddContactUs = ({smallStyle, language}) =>{
	return(
		<div>
			<h1>{language == "EN" ? "Contact Us" : "Hubungi Kami"}</h1>
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.4074452256978!2d106.7711968!3d-6.209869599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f700218507ef%3A0xd49fd0482ef9406c!2sPT%20Anex%20Mitra%20Internusa!5e0!3m2!1sen!2shk!4v1723623462034!5m2!1sen!2shk" className="GoogleMaps" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

			<div className="ContactBodyHome">
				<div className={!smallStyle ? "ContactColumnHome" : "ContactColumnHomeSmall"}>
					{language == "EN" ? <h2>ADDRESS</h2> : <h2>ALAMAT</h2>}
					<label>Jl. Panjang Arteri Klp. Dua Raya, No. 61 Blok D-E, RT.1/RW.2, Klp. Dua, Kec. Kb. Jeruk, Kota Jakarta Barat, DKI Jakarta, 11550, Indonesia</label>
				</div>

				<div className={!smallStyle ? "ContactColumnHome" : "ContactColumnHomeSmall"}>
					<h2>E-MAIL</h2>
					<label><a href="mailto:pdd@pasarnow.com">pdd@pasarnow.com</a></label>

				</div>

				<div className={!smallStyle ? "ContactColumnHome" : "ContactColumnHomeSmall"}>
					<h2>WHATSAPP</h2>
					<label><a href="https://wa.me/+6282130009931" target="_blank">+62-821‑3000‑9931</a></label>
				</div>
			</div>
		</div>
	)
}

export default PddContactUs;