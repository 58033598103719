import React from 'react'
import './navBar.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router";

//images
import logo from "../../images/PDD_Logo_Small.png";
import menuIcon from "../../images/BurgerMenu.svg";
import closeIcon from "../../images/closex.svg";
import indonesianFlag from "../../images/indonesianFlag.png";
import britishFlag from "../../images/britishFlag.png";


const NavBar = ({onSetLanguage, language}) =>{

	const [dropdown, setDropdown] = useState(false);
	const [useDropDownMenu, setUseDropDownMenu] = useState(false);
  	const navigate = useNavigate();
  	const [smallStyle, setSmallStyle] = useState(false);

  	const SetLang = () =>{
  		let lang = "ID";

  		if (language == "ID")
  		{
  			lang = "EN";
  		}

  		onSetLanguage(lang);
  	}

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1280)			
			{
				setSmallStyle(true);
				setUseDropDownMenu(true);
			}
			else
			{
				setSmallStyle(false);
				setUseDropDownMenu(false);
			}
	    });

	}, [smallStyle])

	  
	//On initial window load
	useEffect(()=>{
	    
		let screenWidth = document.documentElement.clientWidth;

	    if (screenWidth <= 1280)
	    {
	      setSmallStyle(true)
	      setUseDropDownMenu(true);
	    }
	    else
	    {
	      setSmallStyle(false);
	      setUseDropDownMenu(false);
	    }

	}, [smallStyle])

  	const ShowHideDropDown = () =>{
		let show = !dropdown;

		console.log(`Showing Drop Down: ${show}`);

		setDropdown(show);
	}

	const GoToPage = (url) =>{
    	navigate(url);
    	setDropdown(false);
    	window.scrollTo(0,0);
  	}

  	const GoToSection = (url, section) =>{
	    navigate(url);
	    setDropdown(false);

	    setTimeout(()=>{

	      let offsetVariable = 80;

	      if (smallStyle)
	      {
	        offsetVariable = 50;
	      }

	      let posY = document.getElementById(section).offsetTop - offsetVariable;

	      window.scrollTo(0, posY)

	    }, 300);
	  }

  	useEffect(()=>{
		//console.log(`Dropdown state : ${dropdown}`)

		if (dropdown)
		{
		  document.addEventListener('mousedown', (event)=>{
		    //console.log(event.srcElement.id);
		    if (event.srcElement.id !== "NavDropDown")
		    {
		      setDropdown(false)
		    }
		  });
		}

	}, [dropdown]);



  	const NavDropDown = ()=>{
		return (
		  <div className="NavbarDropDown">
		    <img className="menuIcon" src={dropdown ? closeIcon : menuIcon} onClick={() => {
		      setDropdown((prev) => !prev)
		    }}/>
		    <nav className={`HideDropDownNav ${dropdown ? "DropDownNav" : ""}`} id="NavDropDown">
		      <ul>
		        <li onClick={()=>{GoToPage("/")}} id="NavDropDown"><label id="NavDropDown">Home</label></li>
		        <li onClick={()=>{GoToSection("/", "PDDIntro")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Introduction" : "Perkenalan"}</label></li>
		        <li onClick={()=>{GoToSection("/", "ValueProposition")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Proposition" : "Proposisi"}</label></li>
		        <li onClick={()=>{GoToSection("/", "OurProducts")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Products" : "Produk"}</label></li>
		        <li onClick={()=>{GoToSection("/", "KeyBenefits")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Benefits" : "Manfaat"}</label></li>
		        <li onClick={()=>{GoToSection("/", "MarketFootPrint")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Market" : "Pasar"}</label></li>
		        <li onClick={()=>{GoToSection("/", "DistributionCapabilities")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Capabilities" : "Kemampuan"}</label></li>
		        <li onClick={()=>{GoToSection("/", "ContactUs")}} id="NavDropDown"><label id="NavDropDown">{language == "EN" ? "Contact Us" : "Hubungi Kami"}</label></li>
		      </ul>
		    </nav>
		    <div className="LanguageDisplaySmall" onClick={()=>{SetLang()}}>{language == "ID" ? <img src = {indonesianFlag}/> : <img src = {britishFlag}/>}</div>
		 	<img src={logo} className="DropDownPasarnowLogo" onClick={()=>{GoToPage("/")}}/>

		  </div>
		)
	}

	return(
		<div>
		{ !useDropDownMenu ?
			<div className="navbar">
				<nav className="navcontrols">
					<ul>
						<li onClick={()=>{GoToPage("/")}}><img src={logo} className="logo"/></li>
						<li onClick={()=>{GoToSection("/", "PDDIntro")}}>{language == "EN" ? "Intro" : "Perkenalan"}</li>
						<li onClick={()=>{GoToSection("/", "ValueProposition")}}>{language == "EN" ? "Proposition" : "Proposisi"}</li>
						<li onClick={()=>{GoToSection("/", "OurProducts")}}>{language == "EN" ? "Products" : "Produk"}</li>
						<li onClick={()=>{GoToSection("/", "KeyBenefits")}}>{language == "EN" ? "Benefits" : "Manfaat"}</li>
						<li onClick={()=>{GoToSection("/", "MarketFootPrint")}}>{language == "EN" ? "Market" : "Pasar"}</li>
						<li onClick={()=>{GoToSection("/", "DistributionCapabilities")}}>{language == "EN" ? "Capabilities" : "Kemampuan"}</li>
						<li onClick={()=>{GoToSection("/", "ContactUs")}}>{language == "EN" ? "Contact Us" : "Hubungi Kami"}</li>
						<li className="LanguageDisplay" onClick={()=>{SetLang()}}>{language == "ID" ? <img src = {indonesianFlag}/> : <img src = {britishFlag}/>}</li>
					</ul>
				</nav>
			</div>	
			:
			<NavDropDown/>
		}
		</div>	
		
	)
}


export default NavBar;