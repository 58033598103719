import React from 'react';
import './home.css';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

//Page Sections

import PddIntro from "../../components/Page_Sections/PddIntro";
import PddValues from "../../components/Page_Sections/PddValues";
import PddOurProducts from "../../components/Page_Sections/PddOurProducts";
import PddKeyBenefits from "../../components/Page_Sections/PddKeyBenefits";
import PddOurMarketFootprint from "../../components/Page_Sections/PddOurMarketFootprint";
import PddDistributionCapabilities from "../../components/Page_Sections/PddDistributionCapabilities";
import PddContactUs from "../../components/Page_Sections/PddContactUs";

//images
import warehouseLaptop from "../../images/warehouse_laptop.jpg";
import pddLogoWhite from "../../images/PDD_Logo.png";



const Home = ({language})=>{
	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1280)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1280)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])





	

	return(
		<div className={!smallStyle ? "Distributorship" : "DistributorshipSmall"}>

			<Helmet>
				<title>PDD- Pasarnow Distribusi Digital</title>
				<meta name="description" content="Pasarnow Distribusi Digital (“PDD”) is a digitally-enabled distribution company that collaborates with leading manufacturers in Indonesia to distribute quality products to various types of customers. We help Brands & Principals penetrate alternative & mass-market channels more effectively ." />
				<meta name="keywords" content="PDD, Online Market, Distributor, Pasarnow, Health, Personal Care, Snacks, Food, Beverages, Baby Products, Children's Products, Pet Food, Pet Supplies" />
			</Helmet>

			<div className={!smallStyle ? "pddCover" : "pddCoverSmall"}>
				<img src={pddLogoWhite}/>
				<div className={!smallStyle ? "pddCoverText" : "pddCoverTextSmall"}>
					<h2>The Trusted</h2> 
					<h2>Distribution Partner</h2>
					<h2>To Grow</h2>
					<h2>Your Business</h2>
				</div>
			</div>
			

			<div className={!smallStyle ? "HomePDDInfo" : "HomePDDInfoSmall"} id="PDDIntro">
				<PddIntro language = {language}/>
			</div>

			<img className="warehouseLaptopImage" src={warehouseLaptop}/>

			<div className={!smallStyle ? "valuePropositions" : "valuePropositionsSmall"} id="ValueProposition">
				<PddValues smallStyle = {smallStyle} language = {language}/>
			</div>

			<div className={!smallStyle ? "OurProducts" : "OurProductsSmall"} id="OurProducts">
				<PddOurProducts language = {language}/>
			</div>

			<div className="KeyBenefitsBlock" id="KeyBenefits">
				<PddKeyBenefits smallStyle = {smallStyle} language = {language}/>
			</div>

			<div className={!smallStyle ? "OurMarketFootprintTop" : "OurMarketFootprintTopSmall"} id="MarketFootPrint">
				<PddOurMarketFootprint smallStyle = {smallStyle} language = {language}/>
			</div>

			<div className={!smallStyle ? "distributionCapabilities" : "distributionCapabilitiesSmall"} id="DistributionCapabilities">
				<PddDistributionCapabilities smallStyle = {smallStyle} language = {language}/>
			</div>

			<div className={!smallStyle ? "contactUs" : "contactUsSmall"} id="ContactUs">
				<PddContactUs smallStyle = {smallStyle} language = {language}/>
			</div>
			
		</div>
	)
}

export default Home;